.trainer-dashboard-profile {
    padding: 20px;
}

.trainer-dashboard-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.trainer-dashboard-timeslotschedule {
    flex: 1;
    margin-right: 20px;
}

.trainer-dashboard-bookinglist {
    flex: 1;
}

@media (max-width: 768px) {
    .trainer-dashboard-container {
        flex-direction: column;
        padding: 10px;
    }

    .trainer-dashboard-timeslotschedule, 
    .trainer-dashboard-bookinglist {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .trainer-dashboard-profile {
        padding: 10px;
    }
}