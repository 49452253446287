.cta-main-container {
  /* width: 70%; */
  margin: 0 auto;
  background-image: url('/public/images/cta-section-background.webp'); /* Замените этот путь на путь к вашему фоновому изображению */
  background-size: cover; /* Растягивает фон, чтобы он покрывал весь контейнер */
  background-position: center; /* Центрирует фон в контейнере */
  background-repeat: no-repeat; /* Убирает повторение фона */
  height: 45vh; /* Устанавливает высоту контейнера */
  display: flex; /* Включает Flexbox для внутреннего содержимого */
  align-items: center; /* Выравнивает дочерние элементы по центру по вертикали */
  justify-content: center; /* Выравнивает дочерние элементы по центру по горизонтали */
  margin-top: 10px;
  border-radius: 8px;
}

.cta-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-button {
  z-index: 0;
  min-width: 300px;
  min-height: 60px;
  font-family: 'Tektur', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #ffffff;
  background: #ff0000;
  background: linear-gradient(90deg, rgb(112, 18, 1) 0%, rgb(245, 4, 4)50%, rgb(112, 18, 1) 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(255, 0, 0, 0.64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

.cta-button::before {
  content: '';
  border-radius: 1000px;
  min-width: calc(320px + 12px);
  min-height: calc(60px + 12px);
  border: 6px solid #ff0000;
  box-shadow: 0 0 60px rgb(63, 0, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}

.cta-button:hover, .cta-button:focus {
  color: #313133;
  transform: translateY(-6px);
}

.cta-button:hover::before, .cta-button:focus::before {
  opacity: 1;
}

.cta-button::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 6px solid #ff0000;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
  
}

.cta-button:hover::after, .cta-button:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .cta-main-container {
    width: 95%;
  }

  .bright-button {
    width: 180px;
    height: 100px;
  }
}
