.home-page-welcome .p {
    margin: 0;
    font-size: clamp(10px, 1vw, 11px);
    text-align: right;
    padding-right: 15px;
    position: absolute;
    top: 100px;  
    right: 0px;
    font-family: 'Tektur', sans-serif;
}

.go-to-your-zone-text {
    color: red;
}

@media (max-width: 768px) {
    .home-page-welcome p {
        top: 15px;
        right: 70px;
    }
}