/* src/components/mainSection/MainSection.css */
.main-section-container {
    /* width: 70%; */
    margin: 0 auto;
    color: white;
    background-color: black;
    font-family: 'Tektur', sans-serif; /* Простой, но жесткий шрифт */
    font-weight: 700;
    text-align: center;
    /* margin-top: 10px; */
    border-radius: 8px;
    background-image: url('/public/images/main-section-background1.webp');
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .main-section-container h1 {
    font-size: 2.5em;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    margin-top: 10px;
  }
  
  .main-section-container p {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 20px;
    color: red;
    text-shadow:
    -1px -1px 0 white,  
    1px -1px 0 white,
    -1px 1px 0 white,
    1px 1px 0 white;
  }
  
  .main-section-container h2 {
    font-size: 1.8em;
    background: rgba(0, 0, 0, 0.7); /* Слегка затемненный фон для текста */
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    margin: 0px 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    .main-section-container {
      width: 95%;
      display: flex;
      flex-direction: column;

      /* padding: 20px; /* Уменьшаем отступы */
 
      background-image: url('/public/images/main-section-background-mobile.webp');
      background-position: center center; /* Центрирует изображение по обеим осям */
      background-size: cover; /* Растягивает изображение, чтобы оно покрывало весь контейнер */
      background-repeat: no-repeat;
    }
  
    .main-section-container h1 {
      font-size: 1.5em; /* Уменьшаем размер шрифта заголовка */
    }
  
    .main-section-container p {
      padding: 20px;
      font-size: 1em; /* Уменьшаем размер шрифта параграфов */
      color: red;
      text-shadow:
      -1px -1px 0 white,  
      1px -1px 0 white,
      -1px 1px 0 white,
      1px 1px 0 white;
    }
  
    .main-section-container h2 {
      font-size: 1.2em; /* Уменьшаем размер шрифта подзаголовка */
    }
  }
  