/* src/components/auth/registrationForm/registration-form.css */
.registration-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.registration-form .ant-form-item {
  margin-bottom: 16px;
}

.registration-form .ant-input-affix-wrapper {
  padding: 8px;
}

.registration-form .checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.registration-form-button {
  width: 100%;
}

.registration-form .google-recaptcha-custom-class {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .registration-form {
      max-width: none;
      width: 100%;
      padding: 10px;
  }
}
