.time-slot-info {
    display: flex;
    flex-direction: column;
    font-size: clamp(10px, 1vw, 14px); /* Адаптивный размер шрифта */
}

.rating-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.completed-trainings-icon {
    color: gold;
    font-size: clamp(30px, 2vw, 50px);
    margin-right: 5px;
    vertical-align: middle;
}

@media (max-width: 768px) {
    .rating-content-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}