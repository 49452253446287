.dashboard-container {
    display: flex;
    flex-direction: column; /* Элементы теперь располагаются вертикально */
}

.student-dashboard-header {
    width: 100%;
    padding-top: 20px;
}

.header-line {
    height: 5px;
    background-color: black;
    width: 100%;
    margin-top: 10px;
}

.content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.results-container {
    flex: 1;
    max-width: 60%;
    margin-right: 20px;
}

.trainers-container {
    flex: 1;
    max-width: 40%;
}

.search-results-collapse {
    width: 100%;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-or-text-container {
    margin-top: 20px;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .content-container {
        flex-direction: column;
    }

    .results-container, .trainers-container {
        max-width: none;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .student-dashboard-header {
        padding-top: 30px; /* Меньшие отступы на мобильных устройствах */
    }
}
