/* src/components/header/Header.css */
.header-container {

    margin: 0 auto;
    margin-top: 10px;
    left: 0;
    right: 0;
    height: 290px;
    /* background-color: #fff; Можно изменить на нужный цвет */
    z-index: 1000; /* Убедитесь, что заголовок перекрывает другие элементы */
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 8px;
    background-image: url('/public/images/home-page-header-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

@media (max-width: 768px) {
    .header-container {
        background-clip: content-box;
        width: 95%;
        display: flex;
        flex-direction: column;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.9) 80%), url('/public/images/home-page-header-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-width: 0px;
        margin-bottom: 10px;
    }
}