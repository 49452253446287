/* VideoChatStyles.css */

.video-chat-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(82, 58, 58);
}

.video-chat-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.video-chat-video-container {
  position: relative;
  margin: 0 10px;
}

.video-chat-user-video, .video-chat-partner-video {
  border: 20px solid #2b2b2bd3;
  width: 600px;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.video-chat-video-controls {
  border-radius: 5px;
  margin-right: 10px;

}

.video-chat-message-box {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  max-width: 300px;
}

.video-chat-message-text {
  font-size: 16px;
}

.video-chat-row-controls {
  width: 100%;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.video-chat-mute-btn {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .video-chat-user-video, .video-chat-partner-video {
    width: 100%;
    max-width: 300px;
    border-width: 10px;
  }

  .video-chat-row {
    flex-direction: column;
  }

  .video-chat-controls {
    gap: 5px;
  }

  .video-chat-message-box {
    max-width: 100%;
    margin: 5px 0;
  }

  .video-chat-message-text {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .video-chat-container {
    padding: 10px;
  }

  .video-chat-user-video, .video-chat-partner-video {
    max-width: 100%;
    border-width: 5px;
  }

  .video-chat-row {
    flex-direction: column;
  }

  .video-chat-controls {
    gap: 3px;
  }

  .video-chat-message-box {
    padding: 5px;
  }

  .video-chat-message-text {
    font-size: 12px;
  }
}