/* src/components/homePage/benefits/benefitsSecion/BenefitsSection.css */
.benefits-section {
    width: 100%;;
    margin: 0 auto;
    padding: 40px 0px;
    text-align: center;
    background-color: #b3b3b3;
    border-radius: 1%;
    margin-top: 10px;
    background: url('/public/images/benefit-slide-background.webp');
    background-size: cover; /* Растягивает фон, чтобы он покрывал весь контейнер */
    background-position: top; /* Центрирует фон в контейнере */
    background-repeat: no-repeat; /* Убирает повторение фона */
}

.benefits-section h1 {

    margin-bottom: 0px;
    color: #ff0000;
    font-size: 40px;
    text-shadow:
    -1px -1px 0 white,  
    1px -1px 0 white,
    -1px 1px 0 white,
    1px 1px 0 white;
}

@media (max-width: 768px) {
    .benefits-section {
        width: 95%;
    }

    .benefits-section h1 {
        font-size: 25px;
    }
}