.bookinglist-container {
    text-align: center;
}

.bookinglist-container h2 {
    margin-bottom: 0px;
}

/* Стили для заголовков и описаний шагов */
.step-title {
    font-size: 10px; /* Укажите желаемый размер шрифта для заголовка */
    text-align: center;
}

/* Уменьшение расстояния между иконками шагов */
.custom-steps .ant-steps-item {
    flex: none;
    width: 150px; 
}

.bookinglist-item {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    /* padding-left: 24px;  */
}

.bookinglist-booking-title {
    font-size: 10px; /* Размер шрифта заголовка */
    font-weight: bold; /* Толщина шрифта заголовка */
    text-align: right;
    margin-top: 10px;
}

.bookinglist-booking-time {
    color: grey;
    margin-left: 10px;
}

.custom-steps {
    padding-left: 0; /* Уберите отступы, если они были */
    margin-left: 0; /* Уберите отступы, если они были */
    margin-right: -40px; /* Сдвиг вправо на половину ширины шага */
    transform: translateX(40px);
}

.custom-steps .ant-steps-item-title {
    margin-left: 0;
    padding-left: 0;
}

.ant-steps-horizontal {
    margin-top: 0px;
}

@media (max-width: 768px) {
    .custom-steps .ant-steps-item {
        width: 60x; 
    }
    .bookinglist-item {
        width: 95%;
        display: block;
    }
}