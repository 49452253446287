.time-slot-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* Задаём контейнеру полную ширину, чтобы 'space-between' сработало корректно */
    padding: 10px; /* Небольшой внутренний отступ для визуального комфорта */
}

.time-slot-item-date {
    font-size: clamp(10px, 1vw, 14px);
}

.time-slot-item-btn-text {
    font-size: clamp(10px, 1vw, 14px);
}