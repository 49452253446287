@keyframes fadeAndBeat {
    0% {
        opacity: 1;
        transform: scale(1.1);
    }
    50% {
        opacity: 0;
        transform: scale(1);
    }
    100% {
        opacity: 1;
        transform: scale(1.1);
    }
}

.fade-beat {
    animation: fadeAndBeat 5s infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0; /* Задайте желаемый цвет фона */
}
