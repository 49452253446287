.add-time-slot-form-error-message {
    color: red;
    background-color: pink;
    padding: 10px;
    margin-bottom: 10px;
}

/* Стиль для горизонтального расположения (по умолчанию) */
.add-time-slot-form {
    flex-direction: column;
    align-items: flex-start;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 600px) {
    .add-time-slot-form {
        flex-direction: column;
        align-items: flex-start;
    }

    .ant-picker {
        width: 100%;
        max-width: 250px; /* Максимальная ширина для календаря */
        font-size: 9px;
    }
    
    .ant-btn {
        width: 100%;
        max-width: 150px; /* Максимальная ширина для кнопки */
    }

    .ant-picker-time-panel {
        flex-direction: column; /* Изменение направления флекс-контейнера на колонку */
    }
}
