.menu-dashboard {
    font-family: 'Tektur', sans-serif;
}

.menu-dashboard-drawer {
    width: 40%;
}

.menu-dashboard-drawer .ant-menu-item:hover .confirm-email {
    color: black;
}

.confirm-email {
    color: red;
}

@media (max-width: 768px) {
    .menu-dashboard-drawer {
      width: 50%;
    }
}