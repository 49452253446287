.search-trainers-form-timezone-input {
    width: 40%;
}

.search-trainers-form {
    font-family: 'Tektur', sans-serif; 
}

@media (max-width: 768px) {

    .search-trainers-form-timezone-input {
        width: 100%;
    }

}