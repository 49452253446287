.trainer-alias-container {
    display: flex;
    align-items: center;
}

.trainer-alias-input-input {
    width: 200px;
    margin-right: 10px;
}

.trainer-alias-input-btn {
    margin-right: 5px;
    margin-left: 5px;
}

.trainer-alias-display{
    margin-right: 10px;
}
