.menu-auth {
    font-family: 'Tektur', sans-serif;
}

.menu-auth-drawer {
    width: 40%;
    z-index: 10;
}

.menu-item-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 10;
}

.menu-item-icons {
    display: flex;
    gap: 8px; /* Adjust the gap as needed */
}

@media (max-width: 768px) {
    .menu-auth-drawer {
      width: 50%;
    }
}