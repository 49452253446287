/* CallButton.css */
.video-chat-call-btn {
    background-color: green;
    border: none;
    transition: transform 0.3s ease-in-out;
}

.video-chat-call-btn:hover {
    transform: scale(1.1);
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
    }
}
