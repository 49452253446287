/* src/components/homePage/benefits/BenefitSlide.css */
.benefit-slide {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    height: 300px;
    background: #f9f9f9;
    padding: 20px;
    margin: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
}

.benefit-slide h2 {
    margin-bottom: 10px;
    font-size: 24px;
    color: #333;
}

.benefit-slide p {
    font-size: 16px;
    color: #666;
}

@media (max-width: 768px) {
    .benefit-slide {
        padding: 0px;
        margin: 15px;
    }
}