.trainer-photo-container {
    margin-right: 20px; /* Отступ справа */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Убираем выход за пределы круга */
    min-width: 50px; /* Минимальный размер для картинки */
    flex-shrink: 0; /* Предотвращаем сжатие картинки */
}

.trainer-photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.no-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: clamp(10px, 1vw, 14px); /* Адаптивный размер шрифта */
}
