/* src/components/auth/loginForm/login-form.css */
.login-form {
  max-width: 300px;
  margin: 0 auto;
}

.login-form .ant-form-item {
  margin-bottom: 16px;
}

.login-form .ant-input-affix-wrapper {
  padding: 8px;
}

.login-form-button {
  width: 100%;
}

.login-form .google-recaptcha-custom-class {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

  
  @media (max-width: 768px) {
    .login-form {
      max-width: none;
      width: 90%;
    }
  }
  