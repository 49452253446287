.trainer-profile-container {
    display: flex;
    align-items: top;
}

.trainer-profile-alias-input {
    margin-left: 20px;
}

@media (max-width: 768px) {
    .trainer-profile-container {
        flex-direction: column; /* Arrange elements vertically */
        align-items: center; /* Center align items for better appearance */
    }

    .trainer-profile-foto {
        margin: 0 auto;
    }

    .trainer-profile-alias-input {
        margin-left: 0; /* Reset left margin */
        margin-top: 20px; /* Add top margin for spacing */
    }
}