.time-slot-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 0.3s ease;
    padding: 10px 0;
    width: 100%;
}

.info-container {
    display: flex;
    width: 100%;
    justify-content: space-between; /* Равномерно распределяет элементы по горизонтали */
    margin-bottom: 20px; /* Отступ от контейнера до кнопки */
}

.trainer-photo-container,
.completed-trainings,
.rating {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.time-slot-button {
    width: 70%; /* Автоматическая ширина кнопки, адаптируется к содержимому */
    margin: 0 auto; /* Центрирование кнопки */
}

