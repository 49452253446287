/* App.css */
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root, .App {
  width: 70%; 
  margin: 0 auto; /* Автоматические отступы с обеих сторон */
  font-family: 'Tektur', sans-serif; 
}

@media (max-width: 768px) {
  #root, .App {
    width: 95%;
  }
}
