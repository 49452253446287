.rating-container {
    margin-right: 0px; /* Отступ справа */
    display: flex;
    align-items: left;
    /* justify-content: center; */
    text-align: center;
    font-size: clamp(10px, 1vw, 14px); /* Адаптивный размер шрифта */
}
.rate-star-bg {
    color: black;
}

.ant-rate-star-zero .ant-rate-star-first,
.ant-rate-star-zero .ant-rate-star-second {
    color: black
}

/* Стилизация передней половины звезды (активной) */
.ant-rate-star-half .ant-rate-star-first {
    color: red; /* Цвет активной половины звезды */
}

/* Стилизация задней половины звезды (неактивной) */
.ant-rate-star-half .ant-rate-star-second {
    color: black; /* Цвет неактивной половины звезды */
}

.rating-stars {
    align-items: left;
    padding-left: 10px;
}

.rating-text {
    margin-left: 0px;
}

