@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .crosshair-icon {
    transition: transform 0.5s ease-in-out;
  }
  
  .crosshair-icon:hover,
  .crosshair-icon:active {
    animation: rotate 0.5s ease-in-out infinite;
  }
  